<div class="container2" [hidden]="loadPage">
  <div class="flex2">
    <div class="loader2"></div>
  </div>
  <div class="load-text2">
    <span></span>
  </div>
</div>

<!-- cutting image -->
<ng-container>
  <div
    [hidden]="showImage"
    id="exampleModalLive"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLiveLabel"
    style="padding-right: 17px; display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLiveLabel">
            <!-- <ul>
              <li>
                <span>العرض:</span>{{ resizeToWidth }} <span>الطول:</span
                >{{ resizeToHeight }}
              </li>
            </ul> -->
            <span>choose image</span>
          </h3>
          <button
            (click)="cut()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="aspectRatio"
            [cropperMinWidth]="resizeToWidth"
            [cropperMinHeight]="resizeToHeight"
            [resizeToWidth]="resizeToWidth"
            [resizeToHeight]="resizeToHeight"
            [imageQuality]="imageQuality"
            [canvasRotation]="canvasRotation"
            [onlyScaleDown]="true"
            [imageURL]="imgUrl"
            output="base64"
            format="{{ formatImage }}"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          >
          </image-cropper>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="cut()">
            اقتصاص
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            (click)="cut()"
            data-dismiss="modal"
          >
            الغاء
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- cutting image end -->

<!-- save or edit panel -->
<ng-container *ngIf="showDataPanle">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>{{ nameType }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="{{ 'our-team.MainData' | translate }}">
          <div style="margin-top: 14px">
            <div class="col-lg-12">
              <div class="row" style="margin-bottom: 30; margin-top: 20px">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-6 col-sm-12 col-xs-12">
                      <mat-form-field
                        appearance="fill"
                        style="width: 100%; margin: 0; margin-right: 10px"
                      >
                        <mat-label>{{ "our-team.Lang" | translate }}</mat-label>
                        <mat-select
                          (valueChange)="onChangeLang()"
                          matNativeControl
                          [disabled]="disableSelect"
                          [(value)]="selectedLang"
                        >
                          <mat-option>اختر لغة</mat-option>
                          <mat-option
                            *ngFor="let Lang of languages"
                            [value]="Lang.LangId"
                          >
                            {{ Lang.LangName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-xs-12">
                      <mat-form-field
                        appearance="fill"
                        style="width: 100%; margin: 0; margin-right: 10px"
                      >
                        <mat-label>{{
                          "our-team.status" | translate
                        }}</mat-label>
                        <mat-select [(value)]="selected">
                          <mat-option
                            *ngFor="let Statu of Status"
                            [value]="Statu.StateID"
                          >
                            {{ Statu.StateName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div
                      class="col-lg-6 col-sm-12 col-xs-12"
                      [hidden]="hideSection"
                    >
                      <mat-form-field
                        appearance="fill"
                        style="width: 100%; margin: 0; margin-right: 10px"
                      >
                        <mat-label>{{
                          "our-team.section" | translate
                        }}</mat-label>
                        <input
                          type="text"
                          matInput
                          [(ngModel)]="selectedCategories2"
                          [formControl]="groupCtrl"
                          [matAutocomplete]="autoGroup"
                          autocomplete="anyword"
                          [matAutocompleteTrigger]="autoGroup"
                          (click)="openPanelOfGroup()"
                        />
                        <mat-autocomplete
                          #autoGroup="matAutocomplete"
                          (optionSelected)="getGroupValue($event)"
                        >
                          <mat-option
                            *ngFor="let grp of filteredGroup | async"
                            [value]="grp.PostTitle"
                          >
                            <span>{{ grp.PostTitle }}</span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <!-- <div
                    class="col-lg-6 col-sm-12 col-xs-12"
                    [hidden]="hideSection"
                  >
                    <mat-form-field
                      appearance="fill"
                      style="width: 100%; margin: 0; margin-right: 10px"
                    >
                      <mat-label>{{
                        "our-team.section" | translate
                      }}</mat-label>
                      <mat-select
                        matNativeControl
                        [disabled]="DisCategories"
                        [(value)]="selectedCategories"
                      >
                        <mat-option
                          *ngFor="let cate of Categories"
                          [value]="cate.PostId"
                        >
                          {{ cate.PostTitle }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                    <div class="col-lg-12 col-sm-12 col-xs-12">
                      <mat-form-field class="example-full-width">
                        <mat-label
                          >{{ "our-team.Name" | translate }}
                        </mat-label>
                        <input
                          autocomplete="off"
                          [(ngModel)]="ProdTitle"
                          matInput
                        />
                        <mat-icon style="color: #374bab" matSuffix
                          >edit</mat-icon
                        >
                      </mat-form-field>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-xs-12">
                      <mat-form-field class="example-full-width">
                        <mat-label
                          >{{ "our-team.part" | translate }}
                        </mat-label>

                        <textarea [(ngModel)]="ProdSub" matInput></textarea>

                        <mat-icon style="color: #374bab" matSuffix
                          >edit</mat-icon
                        >
                      </mat-form-field>
                    </div>

                    <div
                      class="col-lg-12 col-sm-12 col-xs-12"
                      [hidden]="hideFile"
                    >
                      <div
                        class="uploadfilecontainer"
                        (click)="fileInput_.click()"
                        appDragDrop
                        (onFileDropped)="uploadFile($event.target.files)"
                      >
                        <input
                          hidden
                          type="file"
                          #fileInput_
                          (change)="uploadFile($event.target.files)"
                        />
                      </div>
                      <div
                        class="files-list"
                        *ngFor="let file of files; let i = index"
                      >
                        <a target="_blank" href="{{ file }}">{{ file }}</a>
                        <button
                          class="delete-file"
                          (click)="deleteAttachment(i)"
                        >
                          <mat-icon style="color: #e63333">delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4" [hidden]="hideImages">
                  <div class="row">
                    <div
                      class="col-lg-12 col-sm-12 col-xs-12"
                      style="text-align: center; margin-top: 0px"
                    >
                      <!-- <button
                        matTooltipPosition="above"
                        matTooltip="حذف الصورة"
                        (click)="deleteImg(templateImg)"
                        mat-mini-fab
                        color="accent"
                        aria-label="Example icon button with a plus one icon"
                        style="
                          position: absolute;
                          left: 150px;
                          bottom: -25px;
                          background-color: #f00;
                        "
                      >
                        <mat-icon>delete</mat-icon>
                      </button> -->

                      <input
                        hidden
                        accept="image/*"
                        (change)="fileChangeEvent($event)"
                        #fileInput
                        type="file"
                        id="file"
                      />

                      <img
                        [src]="croppedImage"
                        (click)="fileInput.click()"
                        width="200"
                        height="200"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions>
      <p style="text-align: center">
        <button
          *ngIf="showSave"
          style="
            bottom: 50%;
            margin-right: 10px;
            background-color: blue;
            z-index: 2;
            width: 60px;
            height: 60px;
          "
          [disabled]="!form.valid"
          matTooltip="حفظ البيانات"
          class="float"
          mat-fab
          color="primary"
          (click)="save()"
        >
          {{ "btn.add" | translate }}
        </button>
        <button
          *ngIf="showSaveContinue"
          style="
            bottom: 61%;
            margin-right: 10px;
            background-color: blue;
            z-index: 2;
            width: 60px;
            height: 60px;
          "
          [disabled]="!form.valid"
          matTooltip="حفظ البيانات"
          class="float"
          mat-fab
          color="primary"
          (click)="saveandcontinue()"
        >
          {{ "btn.addandcontinue" | translate }}
        </button>
        <button
          *ngIf="showUpdate"
          style="margin-left: 10px"
          style="
            bottom: 50%;
            margin-right: 10px;
            background-color: green;
            z-index: 2;
            width: 60px;
            height: 60px;
          "
          [disabled]="!form.valid"
          class="float"
          mat-fab
          color="primary"
          (click)="update()"
        >
          {{ "btn.update" | translate }}
        </button>

        <button
          *ngIf="showcancel"
          matTooltip="الغاء العملية"
          style="
            bottom: 39%;
            margin-right: 10px;
            background-color: orangered;
            z-index: 2;
            width: 60px;
            height: 60px;
          "
          class="float"
          (click)="btnclose()"
          mat-fab
          color="primary"
        >
          {{ "btn.close" | translate }}
        </button>
      </p>
    </mat-card-actions>
  </mat-card>
  <!-- *ngIf="TYPE_ != 'upload_result'" -->
  <mat-card style="margin-top: 14px">
    <div style="margin-top: 14px">
      <div class="col-lg-12">
        <div class="row" style="margin-bottom: 30; margin-top: 20px">
          <div class="col-lg-12">
            <div class="card-body">
              <form [formGroup]="form">
                <div *ngFor="let group of groups">
                  <h1>{{ group.name }}</h1>
                  <dynamic-form-builder
                    [formGroup]="form.get(group.name)"
                    [fields]="group.fields"
                  >
                  </dynamic-form-builder>
                  <hr />
                </div>
              </form>

              <div [hidden]="hideEditor">
                <angular-editor
                  [placeholder]="'Enter text here...'"
                  [(ngModel)]="ProdDetails"
                  [config]="editorConfig"
                ></angular-editor>
                <!-- app.component.html -->

<!-- <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor> -->
<!-- <ckeditor [editor]="Editor" [config]="{ toolbar: [ 'undo', 'redo','heading', '|','|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code','|', 'alignment','link', 'uploadImage', 'blockQuote', 'codeBlock','|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent' ] }"></ckeditor> -->
<!-- <ckeditor [editor]="Editor" [config]="{toolbar: [ 'undo', 'redo','heading','|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor','bold', 'italic','strikethrough', 'subscript', 'superscript', 'code', '|', 'numberedList', 'bulletedList','|', 'link', 'uploadImage', 'blockQuote', 'codeBlock']}"></ckeditor> -->


                <!-- <editor
                  [(ngModel)]="ProdDetails"
                  apiKey="wzjn6yno85tgyqedgm6ds0961qnlpqflktlvkdwbn3ook03d"
                  [init]="{placeholder:'Enter text here...',
    plugins: 'mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
    toolbar: 'undo redo | blocks | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
  }"
                ></editor> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
<!-- save or edit panel end -->

<!-- show table data -->
<mat-card
  class="example-card"
  style="margin-top: 10px"
  [hidden]="showTableProd"
>
  <mat-card-header>
    <mat-card-title>{{ nameType }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div style="margin: 20px">
      <p style="text-align: center">
        <button
          *ngIf="showNew"
          style="
            margin-top: -11px;
            margin-bottom: 10px;
            margin-right: 2px;
            float: left;
          "
          mat-raised-button
          color="primary"
          (click)="btnNew()"
        >
          {{ "btn.new" | translate }}
          <mat-icon
            style="
              margin-bottom: -5px;
              margin-right: 5px;
              margin-top: 0px;
              margin-left: -6px;
            "
            >add</mat-icon
          >
        </button>
        {{ "btn.show" | translate }}

        <select (change)="getPage($event)">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="250">250</option>
          <option value="500">500</option>
        </select>
      </p>
      <mat-form-field
        appearance="fill"
        style="width: 100%; margin: 0; margin-right: 10px"
      >
        <mat-label>{{ "our-team.Lang" | translate }}</mat-label>
        <mat-select
          (valueChange)="getByLang()"
          matNativeControl
          [(value)]="LANG_"
        >
          <mat-option *ngFor="let Lang of languages" [value]="Lang.LangId">
            {{ Lang.LangName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        [hidden]="ShowOrder"
        (click)="btnCloseOrderClick()"
        style="
          margin-top: -11px;
          background-color: orangered;
          margin-bottom: 10px;
          margin-right: 2px;
          float: right;
        "
        mat-raised-button
        color="primary"
      >
        الغاء<mat-icon
          style="
            margin-bottom: -5px;
            margin-right: 5px;
            margin-top: 0px;
            margin-left: -6px;
          "
          >close
        </mat-icon>
      </button>

      <button
        [hidden]="ShowOrder"
        style="
          margin-top: -11px;
          margin-bottom: 10px;
          margin-right: 2px;
          float: right;
        "
        mat-raised-button
        color="primary"
        (click)="btnSaveOrderClick()"
      >
        تطبيق<mat-icon
          style="
            margin-bottom: -5px;
            margin-right: 5px;
            margin-top: 0px;
            margin-left: -6px;
          "
          >reorder
        </mat-icon>
      </button>

      <table
        style="
          width: 100%;
          max-width: 100%;
          margin-bottom: 1.143rem;
          background-color: transparent;
        "
        class="table table-bordered table-striped"
      >
        <thead>
          <tr>
            <th style="padding: 3px; text-align: center" scope="col">#</th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "our-team.Name" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "our-team.section" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "our-team.status" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "our-team.Lang" | translate }}
            </th>

            <th style="padding: 3px; text-align: center" scope="col">
              {{ "our-team.Processes" | translate }}
            </th>
          </tr>
        </thead>
        <tbody
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          style="text-align: center !important"
        >
          <tr *ngFor="let item of tableProd; let i = index" cdkDrag>
            <th style="padding: 3px" scope="row">{{ i + 1 }}</th>
            <td style="padding: 3px">{{ item.postTitle }}</td>
            <td style="padding: 3px">{{ item.parentName }}</td>
            <td style="padding: 3px">{{ item.statusName }}</td>
            <td style="padding: 3px">{{ item.langId }}</td>
            <td style="padding: 3px; width: 150px">
              <button
                style="margin-left: 3px"
                matTooltipPosition="above"
                matTooltip="تعديل"
                (click)="showData(item.postId, item.langId)"
                mat-mini-fab
                color="primary"
                aria-label="Example icon button with a menu icon"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                matTooltipPosition="above"
                style="margin-left: 3px"
                matTooltip="  لغة جديدة"
                (click)="showProdlang(item.postId, item.langId)"
                mat-mini-fab
                mat-mini-fab
                color="primary"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>translate</mat-icon>
              </button>
              <button
                matTooltipPosition="above"
                matTooltip="حذف"
                (click)="
                  delete(template, item.postId, item.langId, item.postFile)
                "
                mat-mini-fab
                color="accent"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody
          *ngIf="TYPE_ == 'Univ.MainMenu'"
          style="text-align: center !important"
        >
          <tr *ngFor="let item of tableProd; let i = index">
            <th style="padding: 3px" scope="row">{{ i + 1 }}</th>
            <td style="padding: 3px">{{ item.postTitle }}</td>
            <td style="padding: 3px">{{ item.parentName }}</td>
            <td style="padding: 3px">{{ item.statusName }}</td>
            <td style="padding: 3px">{{ item.langId }}</td>
            <td style="padding: 3px; width: 150px">
              <button
                style="margin-left: 3px"
                matTooltipPosition="above"
                matTooltip="تعديل"
                (click)="showData(item.postId, item.langId)"
                mat-mini-fab
                color="primary"
                aria-label="Example icon button with a menu icon"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                matTooltipPosition="above"
                style="margin-left: 3px"
                matTooltip="  لغة جديدة"
                (click)="showProdlang(item.postId, item.langId)"
                mat-mini-fab
                mat-mini-fab
                color="primary"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>translate</mat-icon>
              </button>
              <button
                matTooltipPosition="above"
                matTooltip="حذف"
                (click)="
                  delete(template, item.postId, item.langId, item.postFile)
                "
                mat-mini-fab
                color="accent"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li *ngFor="let item of pageNumbers" class="page-item">
            <button (click)="getRows(item.numPage)" class="page-link">
              {{ item.namePage }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </mat-card-content>
</mat-card>
<!-- show table data end -->

<!-- confirm delete row panel -->
<ng-template #template>
  <div class="modal-body text-center">
    <p>هل انت متأكد من اكمال العملية المطلوبة؟</p>
    <button
      *ngIf="TYPE_ != 'upload_result'"
      type="button"
      class="btn btn-default"
      (click)="confirm()"
    >
      تأكيد
    </button>
    <button
      *ngIf="TYPE_ == 'upload_result'"
      type="button"
      class="btn btn-default"
      (click)="confirmDel()"
    >
      تأكيد
    </button>
    <button type="button" class="btn btn-primary" (click)="decline()">
      الغاء
    </button>
  </div>
</ng-template>
<!-- confirm delete row panel end -->

<!-- confirm delete image panel -->
<ng-template #templateImg>
  <div class="modal-body text-center">
    <p>هل انت متأكد من حذف الصورة؟</p>
    <button type="button" class="btn btn-default" (click)="confirmImg()">
      تأكيد
    </button>
    <button type="button" class="btn btn-primary" (click)="decline()">
      الغاء
    </button>
  </div>
</ng-template>
<!-- confirm delete image panel end -->
